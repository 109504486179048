"use strict";

// DEV pane toggler
//$('#pills-account-tab').click();


// -----------------------------
// FILE UPLOADER
// keep me above other file input events.

// on form change check file size
$(':file').on('change', function () {
   var fileSize = this.files[0].size;

   if ($(this).hasClass('brochure-input') == true) {

      if (fileSize > 1820000) {
         alert('That file is too big, please limit each brochure file to 1.7 mb.');
         $(this).val('')

      } else {
         var fileName = $(this).val().split('\\').pop();
         $(this).next('.custom-file-label').html(fileName);
      }

   } else if (fileSize > 1100000) {
      alert('That file is too big, please limit each file to 1 mb.');
      $(this).val('')
   } else {
      var fileName = $(this).val().split('\\').pop();
      $(this).next('.custom-file-label').html(fileName);
   }
});
// -----------------------------



// if no logo change use existing fieldset (because it is found first in the DOM), if there is remove existing fieldset
var logoInputFile = $(':input[name=sp-logo][type=file]');
var logoInputHidden = $(':input[name=sp-logo][type=hidden]');

logoInputFile.change(function () {
   // if the new file was added correctly to the input object (has a value), remove the old hidden one
   if (logoInputFile.val() != '') {
      logoInputHidden.remove();
   }
});


// search submit
var form = document.getElementById("searchForm");

document.getElementById("searchSubmit").addEventListener("click", function () {
   form.submit();
});






//function loadMode() {
//  $('#loaderModal').modal('show');
//}


// empty file uploads on back/forward nevigation
//  window.history.back();
//  $(window).bind("pageshow", function() {
//      //debugger; you can check and see if this block of code gets triggered
//      $('input[id="Attachment"]').val('').end();
//   });







// event page image enlarge

$('[data-fancybox]').fancybox({
   clickContent: "close",
   buttons: ["zoom", //"share",
  "slideShow", //"fullScreen",
  "download", "thumbs", "close"]
});


// remove 'On Today' section if there are no events on today
// if the element is empty, remove the parents parent entirely

var todayEventsElem = $('#todayEvents');

if ($.trim(todayEventsElem.html()) == '') {
   todayEventsElem.parent().parent().remove();
}


// remove duplicate events on the home page under current month

var thisMonth = $('#thisMonthContainer').children().toArray();
var thisMonthFiltered = [];
thisMonth.forEach(function (elem, index) {
   $(elem).addClass('d-none');
   var elemParent = elem;
   elem = elem.innerHTML; // if NOT in the array, add it... note the !

   if (!thisMonthFiltered.includes(elem)) {
      thisMonthFiltered.push(elem);
      $(elemParent).addClass('d-block');
   }
});


// MODAL
// dashboard, new event

$('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
   var newTab = e.target; //   if the newly activated tab has the href #pills-new, show modal

   if ($(newTab).attr('href') == "#pills-new") {
      $('#createModal').modal('toggle');
   } //    e.target is the newly activated tab
   //    e.relatedTarget is the previous active tab

});








// character counter for org about section

$('#accountAbout').on('input', function () {
   var maxlength = $(this).attr('maxlength');
   var currentLength = $(this).val().length;

   if (currentLength >= maxlength) {
      $('#charCount').css('color', 'red').html('(0 characters remaining)');
   } else {
      $('#charCount').css('color', 'black').html('(' + (maxlength - currentLength) + ' characters remaining)');
   }
});



//----------------------
// EVENT EDITOR
//----------------------
// TITLE, swap out any ' characters

$('#eventTitleInput').change(function () {
   var title = $(this).val();
   var titleFix = title.replace(/\'/g, '');
   $(this).val(titleFix);
});





// Single Date or Multi date

function dateCheck() {
   var datepickerMode = "multiple"; // default mode

   var datepickerLabel = $('#datepicker-label');
   var datePicker = $('#datepicker');
   var dateToggle = $('input[name=dateOption]:checked').val();
   datePicker.attr('disabled', false); // get the value of the date element

   var dateValue = $('#datepicker').val(); // create a date from it

   var existingDate = new Date(dateValue); // if it is invalid (eg. multiple dates) then empty variable

   if (isNaN(existingDate.getTime())) {
      existingDate = "";
   }

   if (dateToggle == 'single') {
      datepickerLabel.text("Select Date");
      datepickerMode = "single";
   } else if (dateToggle == 'multiple') {
      datepickerLabel.text("Select Multiple Dates");
      datepickerMode = "multiple";
   } // initiate date picker


   var calendar = datePicker.flatpickr({
      mode: datepickerMode,
      defaultDate: existingDate,
      // this will only be used when editing events
      altInput: true,
      altFormat: "F j, Y",
      // how it appears
      dateFormat: "D M d Y" // how it is stored

   }); // open it

   calendar.open(); // hack to ensure date and time is set before submission

   $('.flatpickr-input:visible').on('focus', function () {
      $(this).blur();
   });
   $('.flatpickr-input:visible').prop('readonly', false); // record single or mutli option in secret input for statamic

   $('input[name=singleOrMulti]').attr('value', dateToggle);
   var dateInput = calendar.selectedDates;

   function dateArrayMaker() {
      var dateInput = calendar.selectedDates.sort(function (a, b) {
         return a.getTime() - b.getTime();
      });
      var dateArrLength = $(dateInput).length;
      $('.multiDateInput').remove();
      var x = dateArrLength;
      var i;

      for (i = 1; i < x; i++) {
         var dateObj = dateInput[i].toDateString();
         $('#datepickerParent').append('<input class="multiDateInput form-control" name="entry_date[' + i + '][date_entry]" id="datepicker" type="hidden" placeholder="' + dateObj + '" value="' + dateObj + '">');
      }

      $('.flatpickr-input').attr('value', dateInput[0].toDateString());
   }

   calendar.config.onValueUpdate.push(dateArrayMaker);
}


// run dateCheck on radio selection


$('input[name=dateOption]').click(dateCheck);



/*
Login swapper
currently not activated, duplicated the login page and linked the create new button to a signup page.
This is because errors dont nest at present and its a little misleading for ux. see:
*/

/* ##################### */

function loginSignupSwap() {
   $('#fadeSwap').fadeOut('fast', function () {
      $('#signupForm').toggleClass('d-block');
      $('#loginForm').toggleClass('d-none');
   }).fadeIn('fast');
}

var thisUrl = $(document).attr('title');

if (thisUrl == "Signup") {
   loginSignupSwap();
}

var btnLink;

function tempRedirect() {
   if (thisUrl == "Signup") {
      btnLink = "login";
   } else {
      btnLink = "signup";
   }

   window.location.href = btnLink;
}

$('#loginForm').toggleClass('d-none');
$('button.loginSignupSwap').click(tempRedirect);






/* ##################### */

//  Make information page links generated by markdown fieldset open in new tabs
$('.file-sections p a').attr('target', '_blank');










